import {css} from '@emotion/react';
import {ContentModule, ModuleProperties} from '@backstage-components/base';
import React, {VFC} from 'react';
import {SchemaType} from './TextInputDefinition';
import {
  Input,
  FormControl,
  FormErrorMessage,
  FormLabel,
} from '@chakra-ui/react';
import {
  ValidationValueMessage,
  useFormContext,
  FieldValues,
  useForm,
  UseFormRegister,
} from 'react-hook-form';
import {ValidationOptions} from './helpers/react-hook-form/typebox-helpers';

export interface ITextInputProps
  extends ModuleProperties,
    Omit<React.InputHTMLAttributes<HTMLInputElement>, 'name' | 'size'>,
    SchemaType {
  validationOptions?: ValidationOptions;
  parentRegister?: UseFormRegister<FieldValues>;
}

export type TextInputComponentDefinition = ContentModule<
  'TextInput',
  ITextInputProps
>;

const filterValidationOptions = (
  options: ValidationOptions,
  type: string
): ValidationOptions => {
  const sharedOptions = ['required'];
  const textOptions = ['minLength', 'maxLength', ...sharedOptions];
  const numberOptions = ['min', 'max', ...sharedOptions];

  const filteredOptions = Object.fromEntries(
    Object.entries(options).filter(([key]) => {
      return type === 'text'
        ? textOptions.includes(key)
        : numberOptions.includes(key);
    })
  );
  return filteredOptions;
};

const processPatternOption = (
  options: ValidationOptions
): ValidationValueMessage<RegExp> => {
  const p = options.pattern;
  if (typeof p === 'undefined') {
    return {value: new RegExp(''), message: ''};
  } else if (p instanceof RegExp) {
    return {value: p, message: ''};
  } else if (
    typeof p === 'boolean' ||
    typeof p === 'number' ||
    typeof p === 'string'
  ) {
    return {value: new RegExp(`${p}`), message: ''};
  } else {
    return p;
  }
};

export const TextInputComponent: VFC<TextInputComponentDefinition> = (
  definition
) => {
  const {
    inputType,
    name,
    disabled,
    label,
    placeholder,
    parentRegister,
    validationOptions,
    sizing,
    value,
    styleAttr,
  } = definition.props;

  const formContext = useFormContext();
  const register = formContext?.register;
  const formState = formContext?.formState;

  const {register: fallbackRegister, formState: fallbackFormState} = useForm({
    mode: 'all',
  });
  const mRegister = parentRegister || register || fallbackRegister;
  const mFormState = formState || fallbackFormState;

  const errors = mFormState.errors;
  return (
    <FormControl
      id={definition.id}
      css={css`
        ${definition.style}
      `}
      isInvalid={!!errors[name]}
      isRequired={
        validationOptions && validationOptions?.required ? true : false
      }
    >
      {!!label && <FormLabel htmlFor={name}>{label}</FormLabel>}
      <Input
        className="text-input"
        css={css`
          ${styleAttr}
        `}
        {...(validationOptions &&
          mRegister(name, {
            pattern: processPatternOption(validationOptions),
            ...filterValidationOptions(validationOptions, inputType),
          }))}
        disabled={disabled}
        size={sizing?.size || 'md'}
        role="textbox"
        type={inputType}
        name={name}
        id={name}
        aria-label={name}
        placeholder={placeholder}
        value={value}
      />
      {errors?.[name]?.message && errors[name].message !== 'true' && (
        <FormErrorMessage className="text-input-error-message">
          {errors[name].message}
        </FormErrorMessage>
      )}
    </FormControl>
  );
};
