export {TextInputComponent} from './TextInputComponent';
export type {
  TextInputComponentDefinition,
  ITextInputProps,
} from './TextInputComponent';
export {
  defaultFieldData,
  schema,
  uiSchema,
  ComponentDefinition,
} from './TextInputDefinition';
export type {SchemaType} from './TextInputDefinition';
export {TextInputPreviewComponent} from './TextInputPreviewComponent';
export type {TextInputProps} from './TextInputPreviewComponent';
export {textValidationOptionGroupSchema} from './helpers/react-hook-form/typebox-helpers';

import {Registry} from '@backstage-components/base';
import {TextInputModule} from './TextInputModule';
import {ComponentDefinition} from './TextInputDefinition';
Registry.register(ComponentDefinition, TextInputModule);
